import { AccordionActions, Box, CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectCompaniesAutocompleteList } from 'features/companies/components/SelectCompaniesList';
import { useGetProjectCompaniesQuery, useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';
import { useState } from 'react';
import { StyleCounterBadge, StyledAccordionSummary } from '../utils/drawerStyle';

/**
 * Company Accordion
 * @param {String} projectId
 * @param {String} taskId
 * @param {Object} handleSave
 * @returns
 */
const CompanyAccordion = ({ taskId, handleSave, disableEdit, gantt }) => {
  const task = gantt.getTask(taskId);
  const projectId = task?.project_id;
  const [selected, setSelected] = useState(task?.companies || []);
  const [isOpen, setIsOpen] = useState(false);

  const { data: companies = [], isLoading } = useGetProjectCompaniesQuery({ projectId });
  const { data: company = { users: [] }, isLoading: isLoadingUserCompany } = useGetWorkspaceCompanyQuery({ projectId });

  const filteredCompanies = companies.filter((obj) => obj.id !== company.id)


  const handleCancelEditing = () => {
    setSelected(task?.companies || []);
  };

  const handleUpdate = () => {
    handleSave({ companies: [...selected] });
  };

  return (
    <Accordion
      expanded={isOpen}
      style={{
        boxShadow:
          'rgba(0, 0, 0, 0.2) 1px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 1px 1px 1px 2px',
      }}
      onChange={() => setIsOpen(!isOpen)}
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon style={{ padding: 0 }} />}>
        <Typography>Companies</Typography>
        <StyleCounterBadge count={[...selected].length}>{[...selected].length}</StyleCounterBadge>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Box maxHeight={'30vh'} width={'100%'}>
          <SelectCompaniesAutocompleteList
            companies={companies}
            disabled={disableEdit}
            isLoading={isLoading}
            selected={selected}
            setSelected={setSelected}
          />
        </Box>
      </AccordionDetails>
      {!disableEdit && (
        <AccordionActions>
          <Button size="small" onClick={handleCancelEditing}>
            Cancel
          </Button>
          <Button
            color="primary"
            size="small"
            type="submit"
            variant="contained"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

export default CompanyAccordion;
