import CalendarIcon from '@blackhyve/common/src/icons/Calender';
import PersonIconComponent from '@blackhyve/common/src/icons/Person';
import { LockOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { parseDuration } from 'components/projectOverview/gantt/ganttConfig/column/menu/durationMenu';
import ganttStore from 'components/projectOverview/gantt/ganttConfig/ganttStore';
import { addDays, differenceInDays, isAfter, isSameDay, isValid } from 'date-fns';
import { selectCurrentUser } from 'features/auth';
import { StyledInlineDatePicker, StyledLabel } from 'features/drawers/utils/drawerStyle';
import { DateValue, LabelValue, SectionHeading } from 'features/gantt/components/TaskDetailDrawer';
import { useEffect, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Controller, useForm } from 'react-hook-form';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSelector } from 'react-redux';
import { useModal } from 'components/common/ModalProvider';
import { Label as LabelComponent } from 'features/labels/components/Label';
import { parseDate } from '@blackhyve/utilities/dates';

const isValidDuration = (input) => {
  if (typeof input === 'string') {
    const regex = /^(\d+)\s+(day|week|month|year|d|w|m|y)s?$/i;
    return regex.test(input) || !isNaN(Number(input));
  }

  return typeof input === 'number' && Number.isFinite(input);
};

/**
 * Timeline Row
 * @param {String} taskId
 * @param {String} ganttId
 * @param {Boolean} disableEdit
 * @returns
 */
const TaskDates = ({ taskId, ganttId, disableEdit }) => {
  const gantt = ganttStore.getGantt(ganttId);
  const { openModal } = useModal();
  const task = gantt.getTask(taskId);
  const isParent = gantt.getTask(taskId)?.type === gantt.config.types.project;
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const [isFormValid, setIsFormValid] = useState(false);

  const initialState = useMemo(
    () => ({
      start_date: task?.start_date,
      scheduled_end_date: task?.scheduled_end_date,
      forecasted_end_date: task?.forecasted_end_date,
      forecasted_start_date: task?.forecasted_start_date
        ? task?.forecasted_start_date
        : task?.start_date,
      actual_start_date: task.actual_start_date,
      actual_end_date: task.actual_end_date,
      effort_hours: task?.effort_hours,
      work_days: task?.work_days,
      cal_days: task?.cal_days,
      crew_size: task?.crew_size,
      dates_locked_by: task?.dates_locked_by ? task.dates_locked_by : false,
      isEditing: false,
    }),
    [task]
  );

  const { control, handleSubmit, watch, setValue, reset, getValues } = useForm({
    defaultValues: initialState,
    mode: 'onBlur',
  });

  const handleStartEditing = () => {
    if (task.status === 'complete') {
      gantt.message({
        type: 'warning',
        text: 'You can not edit a complete task.',
      });
      return false;
    }
    setValue('isEditing', true);
  };

  const handleCloseEditing = () => {
    reset(initialState);
    setValue('isEditing', false);
  };

  const handleUpdate = (data) => {
    data.end_date =
      task.autoschedule_date === 'schedule' ? data.scheduled_end_date : data.forecasted_end_date;

    Object.assign(task, data);
    gantt.updateTask(taskId);
    gantt.autoSchedule(taskId);
    setValue('isEditing', false);
  };

  const handleStuckPointClick = () => {
    openModal('stuckPointDialog', {
      ganttId,
      taskId: task?.id,
      projectId: task?.project_id,
    });
  };

  const watchIsEditing = watch('isEditing');
  const watchScheduledStart = watch('start_date');
  const watchForecastedStart = watch('forecasted_start_date');
  const watchCalDays = watch('cal_days');
  const actualStartDate = watch('actual_start_date');
  const actualEndDate = watch('actual_end_date');
  const datesLockedBy = watch('dates_locked_by');

  const startDate = watchForecastedStart ? watchForecastedStart : watchScheduledStart;
  const procurementAtRisk = task?.procurements?.filter((procurement) => {
    const arrivalDate = procurement.arrival_date ? parseDate(procurement.arrival_date) : null;
    return arrivalDate && (isAfter(arrivalDate, startDate) || isSameDay(arrivalDate, startDate));
  });

  const handleOpenProcurementDialog = () => {
    openModal('procurementDialog', {
      ganttId,
      taskId: task?.id,
      startDate: startDate,
      projectId: task?.project_id,
    });
  }

  //Reset form if initial state changes
  useEffect(() => {
    reset(initialState);
  }, [initialState, reset]);

  // Add a useEffect to watch changes in form values and update the isFormValid state accordingly
  useEffect(() => {
    // Check if the required fields are filled in
    const requiredFieldsFilled = watch('work_days') !== '' && watch('cal_days') !== '';

    // Update the isFormValid state
    setIsFormValid(requiredFieldsFilled);
  }, [watch('work_days'), watch('cal_days')]);



  return (
    <>
      <SectionHeading
        disabled={disableEdit}
        handleEditing={handleStartEditing}
        isEditing={watchIsEditing}
        title="Timeline"
      >
        <CalendarIcon style={{ marginRight: 5 }} />
      </SectionHeading>
      {!watchIsEditing ? (
        <Grid container item spacing={1.5} xs={12}>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Scheduled Start</StyledLabel>
            </Grid>
            <Grid item alignItems={'center'} display={'flex'} xs={5}>
              <DateValue date={getValues('start_date')} />
              {task?.dates_locked_by && (
                <Tooltip
                  arrow
                  placement="top"
                  title={`Scheduled Dates Locked By: ${task?.dates_locked_by?.first_name} ${task?.dates_locked_by?.last_name}`}
                >
                  <LockOutlined fontSize="inherit" />
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Scheduled End</StyledLabel>
            </Grid>
            <Grid item display={'flex'} xs={5}>
              <DateValue date={addDays(getValues('scheduled_end_date'), -1)} />
              {task?.dates_locked_by && (
                <Tooltip
                  arrow
                  placement="top"
                  title={`Scheduled Dates Locked By: ${task?.dates_locked_by?.first_name} ${task?.dates_locked_by?.last_name}`}
                >
                  <LockOutlined fontSize="inherit" />
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Forecasted Start</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <DateValue date={getValues('forecasted_start_date')} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Forecasted End</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <DateValue date={addDays(getValues('forecasted_end_date'), -1)} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Actual Start</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <DateValue date={actualStartDate || '-'} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Actual End</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <DateValue date={actualEndDate ? addDays(actualEndDate, -1) : '-'} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Work Days</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <LabelValue value={getValues('work_days')} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Calendar Days</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <LabelValue value={getValues('cal_days')} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Hours</StyledLabel>
            </Grid>
            <Grid item xs={5}>
              <LabelValue value={getValues('effort_hours')} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={7}>
              <StyledLabel>Crew</StyledLabel>
            </Grid>
            <Grid container item xs={5}>
              <PersonIconComponent style={{ marginRight: 5 }} />
              <LabelValue value={getValues('crew_size')} />
            </Grid>
          </Grid>
          <Grid container item alignItems={'center'} gap={1} xs={12} sx={{ pt: 0 }}>
            <Grid item xs={'auto'}>
              <StyledLabel>
                Open <LabelComponent labelKey="stuck_point" plural={true} />{' '}
              </StyledLabel>
            </Grid>
            <Grid container item alignItems={'center'} xs={4}>
              <LabelValue value={task?.open_stuck_points_count} />
              <IconButton size="medium" onClick={handleStuckPointClick}>
                <OpenInNewIcon color="primary" fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item alignItems={'center'} gap={1} xs={12} sx={{ pt: 0 }}>
            <Grid item xs={'auto'}>
              <StyledLabel>
                Procurements At Risk
              </StyledLabel>
            </Grid>
            <Grid container item alignItems={'center'} xs={4}>
              <LabelValue value={procurementAtRisk?.length} />
              <IconButton size="medium" onClick={handleOpenProcurementDialog}>
                <OpenInNewIcon color="primary" fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <form style={{ paddingLeft: '8px' }} onSubmit={handleSubmit(handleUpdate)}>
          <Grid container item spacing={1} xs={12}>
            <Grid container item alignItems="center" xs={6}>
              <Grid item xs>
                <StyledLabel>Scheduled Start</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="start_date"
                  render={({
                    field: { ref, ...field },
                    fieldState: { invalid, error, ...fieldState },
                  }) => (
                    <StyledInlineDatePicker
                      {...field}
                      disabled={task.status !== 'todo' || Boolean(datesLockedBy)}
                      error={invalid}
                      slotProps={{ textField: { size: 'small' }, helperText: error?.message }}
                      shouldDisableDate={(date) => {
                        return !gantt.isWorkTime({ date, task });
                      }}
                      onChange={(newValue) => {
                        setValue(field.name, newValue);
                        if (isValid(newValue)) {
                          const scheduledEnd = addDays(newValue, watchCalDays);
                          setValue('scheduled_end_date', scheduledEnd);
                          if (task?.status !== 'active' && task.type !== 'parent_task') {
                            setValue('forecasted_start_date', newValue);
                            setValue('forecasted_end_date', scheduledEnd);
                          }
                        }
                      }}
                    />
                  )}
                  rules={{
                    required: 'Scheduled start field is required',
                    validate: {
                      isValidDate: (value) => isValid(value) || 'Invalid Date',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={6}>
              <Grid item xs>
                <StyledLabel>Scheduled End</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="scheduled_end_date"
                  render={({ field, fieldState: { error } }) => (
                    <StyledInlineDatePicker
                      {...field}
                      dis
                      disabled={Boolean(datesLockedBy)}
                      error={!!error}
                      format="MM/dd/yyyy"
                      InputAdornmentProps={{ position: 'start' }}
                      inputVariant="outlined"
                      keyboardIcon={<CalendarIcon fontSize="small" style={{ paddingLeft: 0 }} />}
                      minDate={watchScheduledStart}
                      slotProps={{ textField: { size: 'small', helperText: error?.message } }}
                      value={addDays(field.value, -1)}
                      variant="inline"
                      InputProps={{
                        margin: 'dense',
                      }}
                      shouldDisableDate={(date) => {
                        return !gantt.isWorkTime({ date });
                      }}
                      onChange={(newValue) => {
                        setValue(field.name, newValue);
                        if (isValid(newValue)) {
                          const scheduledStartDate = watchScheduledStart;
                          setValue(
                            'cal_days',
                            differenceInDays(addDays(newValue, 1), scheduledStartDate)
                          );
                          setValue(
                            'work_days',
                            gantt.calculateDuration({
                              start_date: scheduledStartDate,
                              end_date: addDays(newValue, 1),
                              task: task,
                            })
                          );
                          if (task?.status !== 'active' && task.type !== 'parent_task') {
                            setValue('forecasted_end_date', newValue);
                          }
                        }
                      }}
                    />
                  )}
                  rules={{
                    required: 'Schedule end field is required',
                    setValueAs: (value) => addDays(value, 1),
                    validate: {
                      isValidDate: (value) => isValid(value) || 'Invalid Date',
                      isAfterStart: (value) =>
                        watchScheduledStart <= value || 'Cannot be before scheduled start',
                      isWorkingDay: (value) => {
                        const endDate = addDays(value, -1);
                        return (
                          gantt.isWorkTime({ date: endDate, task }) ||
                          'End date must fall on a working day'
                        );
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'dates_locked_by'}
                render={({ field: { value, onChange, ...field }, fieldState }) => (
                  <FormControlLabel
                    {...field}
                    checked={value}
                    control={<Checkbox size={'small'} />}
                    label={'Lock scheduled dates'}
                    disabled={
                      value &&
                      currentUser.role !== 'super_admin' &&
                      currentUser.role !== 'admin' &&
                      currentUser.id !== value.id
                    }
                    onChange={(event) => {
                      onChange(event.target.checked ? currentUser : false);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid container item alignItems="center" xs={6}>
              <Grid item xs>
                <StyledLabel>Forecasted Start</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="forecasted_start_date"
                  render={({ field, fieldState: { error, invalid } }) => (
                    <StyledInlineDatePicker
                      {...field}
                      disabled={true}
                      error={!!error}
                      format="MM/dd/yyyy"
                      helperText={error?.message}
                      InputAdornmentProps={{ position: 'start' }}
                      inputVariant="outlined"
                      slotProps={{ textField: { size: 'small' } }}
                      // value={(field.value)}
                      variant="inline"
                      InputProps={{
                        margin: 'dense',
                      }}
                    />
                  )}
                  rules={{
                    // setValueAs: (value) => (isValid(value) ? format(value, 'yyyy-MM-dd') : value),
                    validate: {
                      isValidDate: (value) => isValid(value) || 'Invalid Date',
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems="center" xs={6}>
              <Grid item xs>
                <StyledLabel>Forecasted End</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="forecasted_end_date"
                  render={({ field, fieldState: { error } }) => (
                    <StyledInlineDatePicker
                      {...field}
                      disabled={true}
                      error={!!error}
                      format="MM/dd/yyyy"
                      helperText={error?.message}
                      InputAdornmentProps={{ position: 'start' }}
                      inputVariant="outlined"
                      keyboardIcon={<CalendarIcon fontSize="small" style={{ paddingLeft: 0 }} />}
                      minDate={watchScheduledStart}
                      slotProps={{ textField: { size: 'small' } }}
                      value={addDays(field.value, -1)}
                      variant="inline"
                      InputProps={{
                        margin: 'dense',
                      }}
                      onChange={(newValue) => {
                        setValue(field.name, newValue);
                      }}
                    />
                  )}
                  rules={{
                    required: 'Forecasted end field is required',
                    setValueAs: (value) => addDays(value, 1),
                    validate: {
                      isValidDate: (value) => isValid(value) || 'Invalid Date',
                      isAfterStart: (value) =>
                        watchForecastedStart <= value || 'Cannot be before forecasted start',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={6}>
              <Grid item xs={5}>
                <StyledLabel>Work Days</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="work_days"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      disabled={isParent || task.status !== 'todo' || Boolean(datesLockedBy)}
                      error={!!error}
                      helperText={error?.message}
                      placeholder="Work Days"
                      size="small"
                      variant="outlined"
                      onChange={(event) => {
                        const newValue = event.target.value;
                        if (!newValue || !isValidDuration(newValue) || parseInt(newValue) <= 0) {
                          setValue(field.name, newValue);
                        } else {
                          const { calendarDays, workdays } = parseDuration(
                            gantt.getTask(taskId),
                            newValue.toString().toLowerCase(),
                            ganttId,
                            true
                          );

                          const endDate = addDays(
                            gantt.calculateEndDate({
                              start_date: watchScheduledStart,
                              duration: workdays,
                            }),
                            -1
                          );
                          setValue('work_days', workdays);
                          setValue('cal_days', calendarDays);
                          setValue('scheduled_end_date', endDate);

                          if (task.status !== 'active' && task.type !== 'parent_task') {
                            setValue('forecasted_end_date', endDate);
                          }
                        }
                      }}
                    />
                  )}
                  rules={{
                    required: 'Work days field is required',
                    validate: {
                      positive: (v) => parseInt(v) > 0 || 'Must be greater than 0',
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={6}>
              <Grid item xs={5}>
                <StyledLabel>Calendar Days</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="cal_days"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      disabled={isParent || task.status !== 'todo' || Boolean(datesLockedBy)}
                      error={!!error}
                      helperText={error?.message}
                      placeholder="Calendar Days"
                      size="small"
                      variant="outlined"
                      onChange={(event) => {
                        const newValue = event.target.value;
                        if (!newValue || !isValidDuration(newValue) || parseInt(newValue) <= 0) {
                          setValue(field.name, newValue);
                        } else {
                          const { calendarDays, workdays } = parseDuration(
                            gantt.getTask(taskId),
                            newValue.toString().toLowerCase(),
                            ganttId,
                            false
                          );
                          const newScheduledEnd = addDays(
                            watchScheduledStart,
                            calendarDays ? calendarDays - 1 : 0
                          );
                          setValue('work_days', workdays);
                          setValue('cal_days', calendarDays);
                          setValue('scheduled_end_date', newScheduledEnd, { shouldValidate: true });

                          if (task.status !== 'active' && task.type !== 'parent_task') {
                            setValue('forecasted_end_date', newScheduledEnd);
                          }
                        }
                      }}
                    />
                  )}
                  rules={{
                    required: 'Calendar days field is required',
                    validate: {
                      positive: (v) => parseInt(v) > 0 || 'Must be greater than 0',
                      isWorkingDay: (v) => {
                        const newEnd = addDays(task.forecasted_start_date, v - 1);
                        return (
                          gantt.isWorkTime({ date: newEnd, task }) ||
                          'End date must fall on a working day'
                        );
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={6}>
              <Grid item xs>
                <StyledLabel>Hours</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="effort_hours"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      disabled={isParent}
                      error={!!error}
                      helperText={error?.message}
                      placeholder="Hours"
                      size="small"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={6}>
              <Grid item xs={5}>
                <StyledLabel>Crew</StyledLabel>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="crew_size"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      placeholder="Crew"
                      size="small"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item gap={2} xs={12}>
              <Button size="small" onClick={handleCloseEditing}>
                Cancel
              </Button>
              <Button disabled={!isFormValid} size="small" type="submit" variant="contained">
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default TaskDates;
